import { useRef } from "react";
import { ChatMessage, ChatMessageAssistant } from "../types";
import ChatMessageComponent from "./ChatMessageComponent";
import { ChatTheme } from "../hooks/useTheme";
import clsx from "clsx";

type QuestionAndAnswerBlockProps = {
  questionAnswerBlock: ChatMessage[];
  setChatHistory: React.Dispatch<React.SetStateAction<ChatMessage[]>>;
  theme: ChatTheme;
  isLatest: boolean;
  responding: boolean;
  handleRegenerateResponse: () => void;
};

const QuestionAndAnswerBlock = ({
  questionAnswerBlock,
  theme,
  setChatHistory,
  isLatest,
  responding,
  handleRegenerateResponse,
}: QuestionAndAnswerBlockProps) => {
  const questionAndAnswerBlockRef = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={questionAndAnswerBlockRef}
      className={clsx(
        "question-and-answer-block",
        questionAnswerBlock[questionAnswerBlock.length - 1].role ===
          "assistant" &&
          !questionAnswerBlock[questionAnswerBlock.length - 1].error &&
          "question-and-answer-block-min-height",
      )}
    >
      {questionAnswerBlock.map((message: ChatMessage, index: number) => (
        <ChatMessageComponent
          setChatHistory={setChatHistory}
          theme={theme}
          key={
            index +
            "_" +
            message.role +
            "" +
            (message as ChatMessageAssistant).text?.substring(0, 20)
          }
          message={message}
          handleRegenerateResponse={handleRegenerateResponse}
          isLatest={isLatest && index === questionAnswerBlock.length - 1}
          responding={responding}
          questionAndAnswerBlockRef={questionAndAnswerBlockRef}
        />
      ))}
    </div>
  );
};

export default QuestionAndAnswerBlock;
