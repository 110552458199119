import { TableHead } from "@bolt/components";
import { DatasetTableSchemaField } from "../types";
import { useCallback } from "react";

type DatasetTableHeaderCellFilterProps = {
  setFilters: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  tableHeader: DatasetTableSchemaField;
  index: number;
  filterValue: string;
};

const FILTER_INPUT_CLASSNAME = "filter-input";

const DatasetTableHeaderCellFilter = ({
  setFilters,
  tableHeader,
  index,
  filterValue,
}: DatasetTableHeaderCellFilterProps) => {
  const handleOnFilterChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      setFilters((state) => ({
        ...state,
        [index]: (event.target as HTMLInputElement)?.value,
      }));
    },
    [setFilters, index],
  );

  return (
    <TableHead
      // Note: even if we display label we want to see the raw name on hover.
      title={tableHeader.name}
      className="table-header-cell-filter"
      key={tableHeader.name + "-" + tableHeader.type}
    >
      {tableHeader.type !== "datetime" && tableHeader.type !== "date" && (
        <input
          type="text"
          className={FILTER_INPUT_CLASSNAME}
          placeholder="Type to filter..."
          value={filterValue}
          onChange={handleOnFilterChange}
        />
      )}
    </TableHead>
  );
};

export default DatasetTableHeaderCellFilter;
