import React, { useCallback, useEffect, useRef, useState } from "react";
import { TextareaAutosize } from "@mui/material";
import { Button, IconButton, Switch } from "@bolt/components";
import { ChatMessage, ChatMessageUser, ChatRequest } from "../../../types";
import "./ChatUiStyles.css";
import MessageBot from "../../MessageBot";
import SkeletonTextBlock from "../../SkeletonTextBlock";
import ChatGPCLogo from "../../ChatGPCLogo";
import LogOutButton from "../../LogOutButton";
import useTheme from "../../../hooks/useTheme";
import DisclosureWarning from "../../DisclosureWarning";
import ChatFooterDisclosure from "../../ChatFooterDisclosure";
import ExampleQuestionTiles from "../../ExampleQuestionTiles";
import { useSetChatDetailsContext } from "../../../contexts/ChatContext";
import AssistantWelcomeMessage from "../../AssistantWelcomeMessage";
import { useChatGpc } from "../../../hooks/backend/useChatGpc";
import useInfo from "../../../hooks/backend/useInfo";
import useFetchInitialData from "../../../hooks/backend/useFetchInitialData";
import QuestionAndAnswerBlock from "../../QuestionAndAnswerBlock";

const ON_BUTTON_ABOUT_CLICKED_MESSAGE = "What are your capabilities?";

const ChatUi = () => {
  const [chatHistory, setChatHistory] = useState<ChatMessage[]>([]);
  const [responding, setResponding] = useState<boolean>(false);
  const [userQuestion, setUserQuestion] = useState<string>("");
  const setChatDetails = useSetChatDetailsContext();
  const msgEnd = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const { info } = useInfo();
  const { askQuestion, abortRequest } = useChatGpc();

  useFetchInitialData();

  const focusOnInput = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus();

      // Note: it sets the cursor position to the end of the string
      //       source: https://stackoverflow.com/a/10576409
      inputRef.current.selectionStart = inputRef.current.selectionEnd = 10000;
    }
  }, []);

  const { theme, toggleTheme } = useTheme();

  // Note: auto-focus initially and after getting the response
  useEffect(() => {
    if (responding === false) {
      focusOnInput();
    }
  }, [responding, focusOnInput]);

  useEffect(() => {
    if (chatHistory.length) {
      // Note: The setTimeout was added to wait for plotly to be initiated.
      //       The auto-scroll doesn't work without it for visualization tab.
      //       It might be refactored and some callback might be used at plotly
      setTimeout(() => {
        msgEnd?.current?.scrollIntoView({ behavior: "smooth", block: "end" });
      }, 50);
    }
  }, [chatHistory]);

  // Refactor/Note: handleAbort and resetChat callbacks potentially might be moved to useChat hook
  const handleAbort = useCallback(async () => {
    await abortRequest();
    setResponding(false);
  }, [abortRequest, setResponding]);

  const resetChat = useCallback(async () => {
    await handleAbort();
    setChatHistory([]);
    setChatDetails({});
  }, [setChatHistory, setChatDetails, handleAbort]);

  const handleSend = async () => {
    sendMessage(userQuestion);
  };

  const sendMessage = async (message: string) => {
    const messageTrimmed = message.replace(/^\s+|\s+$/g, "");
    setUserQuestion("");
    setResponding(true);
    setChatHistory((state) => [
      ...state,
      { content: messageTrimmed, role: "user", error: false },
    ]);

    if (messageTrimmed === ON_BUTTON_ABOUT_CLICKED_MESSAGE) {
      setChatHistory((state) => [...state, { role: "assistant", ...info }]);
    } else {
      const requestBody: ChatRequest = {
        message: messageTrimmed,
      };

      const result: ChatMessage = await askQuestion(requestBody);

      setChatHistory((state) => [...state, result]);
    }

    setResponding(false);
  };

  const handleRegenerateResponse = () => {
    const userQuestion =
      (
        chatHistory
          .slice()
          .reverse()
          .find(
            (message: ChatMessage) => message.role === "user",
          ) as ChatMessageUser
      )?.content || "";

    if (userQuestion) {
      sendMessage(userQuestion);
    } else {
      console.error("The user question from chatHistory is empty!");
    }
  };

  const onKeyDownTextarea = (e: React.KeyboardEvent) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      if (userQuestion !== "") {
        handleSend();
      }
      e.preventDefault();
    }
  };

  const requestAbout = async () => {
    sendMessage(ON_BUTTON_ABOUT_CLICKED_MESSAGE);
  };

  const onClickExampleQuestionTile = useCallback(
    (message: string) => {
      setUserQuestion(message);

      focusOnInput();
    },
    [setUserQuestion, focusOnInput],
  );

  // Note: Divide chatHistory into QuestionAndAnswer blocks
  const questionAnswerBlockList = chatHistory.reduce<ChatMessage[][]>(
    (accumulator, currentValue) => {
      if (currentValue.role === "user") {
        accumulator.push([currentValue]);
      } else {
        accumulator[accumulator.length - 1].push(currentValue);
      }

      return accumulator;
    },
    [],
  );

  return (
    <div className="theme-container" data-theme={theme}>
      <div className={"chat-screen-container"}>
        <div className="sidebar">
          <ChatGPCLogo className="chat-img" />
          <div className="sidebar-actions-center">
            <Button
              variant="outline"
              size="lg"
              data-testid="clear-history-btn"
              onClick={resetChat}
            >
              Reset chat
            </Button>
            <Button size="lg" onClick={requestAbout} variant="outline">
              About
            </Button>
          </div>

          <div className="sidebar-actions-bottom">
            <Switch
              data-testid="dark-mode-toggle"
              onClick={toggleTheme}
              label="dark mode"
              size="md"
              checked={theme === "dark"}
            />
            <LogOutButton />
          </div>
        </div>
        <div className="main">
          <DisclosureWarning />
          <div className="chats">
            <MessageBot>
              <div className="full-width-message-wrapper">
                <AssistantWelcomeMessage />
                {!chatHistory.length && (
                  <ExampleQuestionTiles onClick={onClickExampleQuestionTile} />
                )}
              </div>
            </MessageBot>
            {questionAnswerBlockList.map((questionAnswerBlock, blockIndex) => (
              <QuestionAndAnswerBlock
                theme={theme}
                handleRegenerateResponse={handleRegenerateResponse}
                isLatest={blockIndex === questionAnswerBlock.length - 1}
                responding={responding}
                setChatHistory={setChatHistory}
                questionAnswerBlock={questionAnswerBlock}
              />
            ))}
            {responding && (
              <MessageBot>
                <div className="full-width-message-wrapper">
                  <SkeletonTextBlock />
                </div>
              </MessageBot>
            )}
            <div ref={msgEnd} />
          </div>
          <div className="input-box-container">
            <div className="chat-text-area">
              <TextareaAutosize
                ref={inputRef}
                maxRows={7}
                data-testid="chat-input"
                draggable={false}
                disabled={responding}
                placeholder="Ask me a question"
                onKeyDown={onKeyDownTextarea}
                onChange={(e: React.FormEvent<HTMLTextAreaElement>) =>
                  setUserQuestion(e.currentTarget.value)
                }
                value={userQuestion}
              />
            </div>
            {responding ? (
              <IconButton
                ariaLabel="Stop generating response"
                onClick={handleAbort}
                className="abort-button"
                data-testid="abort-button"
                size="md"
                iconName="xmark-large"
                variant="outline"
                // Note: This is commented because it doesn't work as expected at current version of BOLT package
                // The override has been added at css file
                // intent="destructive"
              />
            ) : (
              <IconButton
                ariaLabel="Send"
                disabled={userQuestion === ""}
                className="send-button"
                data-testid="send-button"
                onClick={handleSend}
                size="md"
                iconName="chevron-right"
                variant="outline"
              />
            )}
          </div>
          <ChatFooterDisclosure />
        </div>
      </div>
    </div>
  );
};

export default ChatUi;
