import { display } from "../utils/displayValues";

import "./DisclosureWarningStyles.css";
import { memo, useState } from "react";
import { AlertContent, Alert } from "@bolt/components";

const DisclosureWarning = () => {
  const [show, setShow] = useState(true);

  if (!show) {
    return null;
  }

  return (
    <div className="chat-header">
      <Alert
        ariaLive="polite"
        showClose
        onDismiss={() => setShow(false)}
        showLeftIcon
        size="sm"
        type="warning"
        hasSeparator={false}
      >
        <AlertContent>
          <input type="checkbox" id="disclaimer-read-more-toggle" hidden />
          <p className="read-more-wrap">
            <span>{display.en.disclosure}</span>
            <label
              htmlFor="disclaimer-read-more-toggle"
              className="read-more-trigger"
            >
              <span className="read-more-text">Read More</span>
              <span className="read-less-text">Read Less</span>
            </label>
          </p>
        </AlertContent>
      </Alert>
    </div>
  );
};

export default memo(DisclosureWarning);
