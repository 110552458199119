import { Icon, TableHead } from "@bolt/components";
import { DatasetTableSchemaField } from "../types";
import { useCallback } from "react";

type DatasetTableHeaderCellTitleProps = {
  setOrderDirection: React.Dispatch<React.SetStateAction<number>>;
  setOrderBy: React.Dispatch<React.SetStateAction<number>>;
  tableHeader: DatasetTableSchemaField;
  index: number;
  orderDirection: number;
  orderBy: number;
};

const FILTER_INPUT_CLASSNAME = "filter-input";

const DatasetTableHeaderCellTitle = ({
  setOrderDirection,
  setOrderBy,
  tableHeader,
  orderDirection,
  orderBy,
  index,
}: DatasetTableHeaderCellTitleProps) => {
  const handleOnClick = useCallback(
    (event: React.FormEvent<HTMLDivElement>) => {
      if (
        (event.target as HTMLDivElement)?.classList.contains(
          FILTER_INPUT_CLASSNAME,
        )
      ) {
        return;
      }

      setOrderBy(index);

      if (orderBy === index && orderDirection === 1) {
        setOrderDirection(-1);
      } else {
        setOrderDirection(1);
      }
    },
    [setOrderBy, setOrderDirection, orderBy, orderDirection, index],
  );

  return (
    <TableHead
      // Note: even if we display label we want to see the raw name on hover.
      title={tableHeader.name}
      className="table-header-cell"
      key={tableHeader.name + "-" + tableHeader.type}
      onClick={handleOnClick}
    >
      {index === orderBy && (
        <Icon
          className="table-header-cell-arrow"
          icon={orderDirection === 1 ? "arrow-down" : "arrow-up"}
        />
      )}
      {tableHeader.label ? tableHeader.label : tableHeader.name}
    </TableHead>
  );
};

export default DatasetTableHeaderCellTitle;
