import { useMemo } from "react";
import Plot from "react-plotly.js";
import {
  ChatMessageAssistantParts,
  ChatMessageDataFrame,
  DataArray,
  DataRow,
} from "../types";
import groupBy from "lodash/groupBy";
import { Data, Layout, Shape } from "plotly.js";

import "./ChatAssistantResponseChartTabStyles.css";
import { ChatTheme } from "../hooks/useTheme";
import sortBy from "lodash/sortBy";
import uniq from "lodash/uniq";

import ReactMarkdown from "react-markdown";
import ChatAssistantResponseChartTabDetails from "./ChatAssistantResponseChartTabDetails";
import doesStringContainsDivisionAggregateName from "../utils/doesStringContainsDivisionAggregateName";
import getDataRowColumn from "../utils/getDataRowColumn";
import SelectColumnToVisualize from "./SelectColumnToVisualize";
import getDateFromDateDTO from "../utils/getDateFromDateDTO";

type ChatAssistantResponseChartTabProps = {
  message: ChatMessageAssistantParts;
  theme: ChatTheme;
  columnToVisualizeIndex: number;
  setColumnToVisualizeIndex: React.Dispatch<React.SetStateAction<number>>;
  date: Date | null;
  setDate: React.Dispatch<React.SetStateAction<Date | null>>;
  detailsResult: ChatMessageDataFrame | undefined;
  setDetailsResult: React.Dispatch<
    React.SetStateAction<ChatMessageDataFrame | undefined>
  >;
  showDateMonthly: boolean;
  showDateYearly: boolean;
};

const DARK_LAYOUT = {
  plot_bgcolor: "#03001f",
  paper_bgcolor: "#03001f",
  font: { color: "#DDD" },
};

const LIGHT_LAYOUT = {
  plot_bgcolor: "white",
  paper_bgcolor: "white",
  font: { color: "#03001f" },
};

const LAYOUT_BAR_CHART: Partial<Layout> = {
  showlegend: false,
  yaxis: {
    automargin: true,
  },
  margin: { l: 150 },
};

export const CHART_WIDTH = 900;

const MAX_TICK_TO_DISPLAY_COUNT = 40;

function generateFilteredDateList(
  startDate: Date,
  endDate: Date,
  datesToRemove: Date[],
): Date[] {
  const dateSetToRemove = new Set(
    datesToRemove.map((date) => date.toLocaleDateString()),
  );
  const filteredDateList: Date[] = [];
  let currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    if (!dateSetToRemove.has(currentDate.toLocaleDateString())) {
      filteredDateList.push(new Date(currentDate));
    }
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return filteredDateList;
}

function formatValue(value: number | string, type: string) {
  switch (type) {
    // Note: implemented https://community.plotly.com/t/format-seconds-to-hh-mm-ss-solved/4500/4
    // TODO: fix for `minutes` bigger than 1440 (24 hours)
    case "minutes":
      const hours = Math.floor(Number(value) / 60);
      const minutes = Number(value) % 60;
      return new Date(
        `2000/01/01 ${hours}:${minutes.toString().padStart(2, "0")}:00`,
      );
    case "percentage":
      return `${value}%`;
    case "currency":
      return "$" + value;
    default:
      return value;
  }
}

function getHoverTemplate(type: string) {
  switch (type) {
    case "minutes":
      return `%{x}<br>%{y}`;
    case "percentage":
      return `%{x}<br>%{y:.2f}%`;
    case "currency":
      // To check with currency
      return `%{x}<br>$%{y:,.2f}`;
    default:
      // TODO: validate this for other types... Does it work correctly?
      return `%{x}<br>%{y}`;
  }
}

function generatePlotlyShapeBasedOnDate(date: Date): Partial<Shape> {
  date.setHours(0, 0, 0, 0);
  const date_start = new Date(date.getTime()).setHours(date.getHours() - 12);
  const date_end = new Date(date.getTime()).setHours(date.getHours() + 12);

  return {
    type: "rect",
    // x-reference is assigned to the x-values
    xref: "x",
    // y-reference is assigned to the plot paper [0,1]
    yref: "paper",
    x0: date_start,
    y0: 0,
    x1: date_end,
    y1: 1,
    fillcolor: "#b3b3b3",
    opacity: 0.2,
    line: {
      width: 0,
    },
  };
}

function getDataXAxisLayoutBasedOnAggregationAndDensity(
  tickCount: number,
  showDateMonthly: boolean,
  showDateYearly: boolean,
  isBarChart: boolean,
): Partial<Layout> {
  if (isBarChart) {
    return {};
  }

  if (showDateYearly) {
    return {
      xaxis: {
        dtick: "M12",
      },
    };
  }

  if (showDateMonthly) {
    return {
      xaxis: {
        dtick: "M1",
      },
    };
  }

  if (tickCount < MAX_TICK_TO_DISPLAY_COUNT) {
    return {
      xaxis: {
        type: "date",
        dtick: "D1",
      },
    };
  }

  return {
    xaxis: {
      tickformatstops: [
        {
          dtickrange: [0, 604800000],
          value: "%m/%d/%Y",
        },
        {
          dtickrange: [604800000, "M1"],
          value: "%b %e",
        },
        {
          dtickrange: ["M1", "M12"],
          value: "%b %Y",
        },
        {
          dtickrange: ["M12", null],
          value: "%Y",
        },
      ],
    },
  };
}

const LAYOUT_LINES_CHART: Partial<Layout> = {
  showlegend: true,
};

function getDateColumnNameIndex(message: ChatMessageAssistantParts): number {
  const index = message.frame?.headers?.findIndex((field) =>
    field.name.includes("_DATE"),
  );
  if (typeof index === "undefined" || index === -1) {
    return 0;
  }
  return index;
}

function countUniqDates(
  message: ChatMessageAssistantParts,
  axisXIndex: number,
) {
  const dates = message.frame?.data.map((row) => row[axisXIndex]);
  return uniq(dates).length;
}

function getIsBarChart(message: ChatMessageAssistantParts) {
  const axisXIndex = getDateColumnNameIndex(message);

  if (axisXIndex !== -1 && countUniqDates(message, axisXIndex) === 1) {
    return true;
  }

  return (
    JSON.stringify(message.details?.parameters?.date_to) ===
      JSON.stringify(message.details?.parameters?.date_from) &&
    message.details?.parameters?.date_to !== undefined
  );
}

const MINIMAL_PERIODS_WITH_DATA_TO_SHOW_LINES_AND_MARKS = 3;

const ChatAssistantResponseChartTab = ({
  message,
  columnToVisualizeIndex,
  setColumnToVisualizeIndex,
  theme,
  date,
  setDate,
  detailsResult,
  setDetailsResult,
  showDateMonthly,
  showDateYearly,
}: ChatAssistantResponseChartTabProps) => {
  const metricName = message.details?.metric_name || "";
  const layoutTheme = theme === "dark" ? DARK_LAYOUT : LIGHT_LAYOUT;
  const isBarChart = getIsBarChart(message);
  const dcColumnIndex =
    message.frame?.headers.findIndex((field) =>
      field.name.toUpperCase().includes("DC_NAME"),
    ) ?? 0;
  const layoutChartTypeBased = isBarChart
    ? LAYOUT_BAR_CHART
    : LAYOUT_LINES_CHART;
  const dataRowColumn = getDataRowColumn(message, columnToVisualizeIndex);
  const dataRowColumnIndex =
    message.frame?.headers.findIndex((field) => field.name === dataRowColumn) ??
    0;
  const dataRowColumnToDisplay = dataRowColumn
    .replace("DC__", "")
    .replaceAll("_", " ");

  const shapes: Partial<Shape>[] = [];
  const summaryText = message.text;
  const dateColumnIndex = getDateColumnNameIndex(message);

  const displayColumnToVisualizeSelection =
    !!message.details?.columns_to_visualize &&
    message.details?.columns_to_visualize.length > 1;

  const displayThreshold =
    message.details?.parameters?.metric_threshold &&
    // Note: Display it only when the default column is chose.
    columnToVisualizeIndex === 0;

  type YAxisType = "minutes" | "currency" | "percentage";
  const yAxisType: YAxisType =
    (message.frame?.headers[dataRowColumnIndex].type as YAxisType) || "";

  function getDataBasedOnChartType(isBarChart: boolean): Data[] {
    if (isBarChart) {
      const dataSorted = sortBy(message.frame!.data as DataArray, [
        (row: DataRow) =>
          row[dcColumnIndex].toString().includes("SUPPLY CHAIN") ? -1 : 1,
        (row: DataRow) =>
          doesStringContainsDivisionAggregateName(String(row[dcColumnIndex])),
        dataRowColumnIndex,
      ]).reverse();

      const dataGrouped: Data = {
        x: dataSorted.map((dataRow) =>
          formatValue(dataRow[dataRowColumnIndex], yAxisType),
        ),
        y: dataSorted.map((dataRow) => dataRow[dcColumnIndex]),
        type: "bar",
        orientation: "h",
        hoverinfo: "x+y",
        hovertemplate: getHoverTemplate(yAxisType),
      };

      return [
        dataGrouped,
        displayThreshold
          ? {
              y: message.frame?.data.map(
                (dataRow) => dataRow[dcColumnIndex] || dataRowColumnToDisplay,
              ),
              x: message.frame?.data.map(() =>
                formatValue(
                  message?.details?.parameters?.metric_threshold!,
                  yAxisType,
                ),
              ),
              name: "threshold",
              mode: "lines",
              line: { dash: "dash" },
              hoverinfo: "x+y",
              hovertemplate: getHoverTemplate(yAxisType),
            }
          : {},
      ];
    } else {
      const axisXIndex = dateColumnIndex;
      const dataGrouped = Object.values(
        groupBy(message.frame?.data || [], dcColumnIndex),
      ).map(
        (row): Data => ({
          x: row.map((dataRow) => new Date(dataRow[axisXIndex])),
          y: row.map((dataRow) =>
            formatValue(dataRow[dataRowColumnIndex], yAxisType),
          ),
          type: "scatter",
          mode:
            countUniqDates(message, axisXIndex) <=
            MINIMAL_PERIODS_WITH_DATA_TO_SHOW_LINES_AND_MARKS
              ? "lines+markers"
              : "lines",
          name: String(row[0][dcColumnIndex]) || dataRowColumnToDisplay,
          hovertemplate: getHoverTemplate(yAxisType),
        }),
      );

      if (displayThreshold) {
        dataGrouped.push({
          x: message.frame?.data.map(
            (dataRow) => new Date(dataRow[axisXIndex]),
          ),
          y: message.frame?.data.map(() =>
            formatValue(
              message?.details?.parameters?.metric_threshold!,
              yAxisType,
            ),
          ),
          name: "threshold",
          mode: "lines",
          line: { dash: "dash" },
          hoverinfo: "x+y",
          hovertemplate: getHoverTemplate(yAxisType),
        });
      }

      // Note: Grey out days without data when the aggregation is daily
      if (message.details?.parameters?.group_by_interval === "DAY") {
        // Step 1: Find days without data.

        const date_to = message.details?.parameters?.date_to
          ? getDateFromDateDTO(message.details?.parameters?.date_to)
          : new Date();

        const date_from = message.details?.parameters?.date_from
          ? getDateFromDateDTO(message.details?.parameters?.date_from)
          : new Date();

        const datesToRemove =
          message.frame?.data.map((row) => new Date(row[axisXIndex])) || [];

        const listGreyedDates = generateFilteredDateList(
          date_from,
          date_to,
          datesToRemove,
        );

        // Step 2: add grey area for days without data.
        listGreyedDates.forEach((greyedDate) =>
          shapes.push(generatePlotlyShapeBasedOnDate(greyedDate)),
        );
      }

      return dataGrouped;
    }
  }

  const plotlyData = getDataBasedOnChartType(isBarChart);

  function getHeightBasedOnChartType(isBarChart: boolean): number {
    if (isBarChart) {
      const SINGLE_BAR_HEIGHT = 16;
      const BASE_HEIGHT_REQUIRED_TO_ALLOCATE_BAR_CHART_SAFELY = 178;

      // @ts-ignore: field exists
      if (plotlyData[0]?.y) {
        // @ts-ignore: field exists
        const countBars = uniq(plotlyData[0].y).length || 50;

        return (
          countBars * SINGLE_BAR_HEIGHT +
          BASE_HEIGHT_REQUIRED_TO_ALLOCATE_BAR_CHART_SAFELY
        );
      }

      return 900;
    }
    return 500;
  }

  // Note/TODO: it would be great to force plotly to update the colors once the theme change
  //            unfortunately it doesn't work by adding theme below to dependency array
  const plotlyDataVersion = useMemo(() => {
    return Math.floor(Math.random() * 99999999);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataRowColumn]);

  const humanFriendlyColumnsToVisualize =
    message.details!.columns_to_visualize.map(
      (columnName) =>
        message.frame?.headers.find((header) => header.name === columnName)
          ?.label || columnName,
    );

  const uniqXAxisPositionCount = countUniqDates(
    message,
    getDateColumnNameIndex(message),
  );

  const yAxisConfig: Record<YAxisType, { tickformat?: string; title: string }> =
    {
      minutes: { tickformat: "%H:%M", title: "HH:MM" },
      currency: { title: "USD" },
      percentage: { title: "%" },
    };

  const yAxisSettings = !isBarChart ? yAxisConfig[yAxisType] || {} : {};

  return (
    <>
      {/* If summary text is present - display it here in visualization tab */}
      {!!summaryText && <ReactMarkdown>{summaryText}</ReactMarkdown>}
      {displayColumnToVisualizeSelection && (
        <SelectColumnToVisualize
          columnToVisualizeIndex={columnToVisualizeIndex}
          setColumnToVisualizeIndex={setColumnToVisualizeIndex}
          columns={humanFriendlyColumnsToVisualize}
        />
      )}
      <div className="plotly-container">
        <Plot
          // Note: once we change it, the plotly will redraw the chart
          revision={plotlyDataVersion}
          data={plotlyData}
          layout={{
            shapes,
            ...(displayColumnToVisualizeSelection
              ? {}
              : { title: dataRowColumnToDisplay }),
            width: CHART_WIDTH,
            ...layoutChartTypeBased,
            ...layoutTheme,
            ...getDataXAxisLayoutBasedOnAggregationAndDensity(
              uniqXAxisPositionCount,
              showDateMonthly,
              showDateYearly,
              isBarChart,
            ),
            height: getHeightBasedOnChartType(isBarChart),
            yaxis: {
              rangemode: "tozero",
              ...yAxisSettings,
            },
          }}
        />
      </div>
      {metricName === "bill_of_lading_on_time_percentage" &&
        message.details?.parameters?.group_by_interval === "DAY" &&
        message.details?.parameters?.dc_name && (
          <ChatAssistantResponseChartTabDetails
            date={date}
            setDate={setDate}
            detailsResult={detailsResult}
            setDetailsResult={setDetailsResult}
            dateVariants={(message.frame?.data || []).map(
              (row) => new Date(row[dateColumnIndex]),
            )}
          />
        )}
    </>
  );
};

export default ChatAssistantResponseChartTab;
