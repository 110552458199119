import { DTO_DATE_FORMAT } from "../types";

function getDateFromDateDTO(dateDTO: DTO_DATE_FORMAT) {
  const date = new Date();

  date.setFullYear(
    parseInt(dateDTO?.year),
    parseInt(dateDTO?.month) - 1,
    parseInt(dateDTO?.day),
  );

  return date;
}

export default getDateFromDateDTO;
