import { Alert, AlertContent, Button, Loading } from "@bolt/components";

import "./ChatAssistantResponseChartTabDetailsStyles.css";
import { useRef, useState } from "react";
import { ChatMessageDataFrame } from "../types";
import DatasetTable from "./DatasetTable";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import getYYYYMMDDDateFormat from "../utils/getYYYYMMDDDateFormat";
import { useChatGpc } from "../hooks/backend/useChatGpc";

type RequestBody = {
  type: "breakdown";
  parameters: {
    date: string;
  };
};

type ChatAssistantResponseChartTabDetailsProps = {
  dateVariants: Date[];
  date: Date | null;
  setDate: React.Dispatch<React.SetStateAction<Date | null>>;
  detailsResult: ChatMessageDataFrame | undefined;
  setDetailsResult: React.Dispatch<
    React.SetStateAction<ChatMessageDataFrame | undefined>
  >;
};

const ChatAssistantResponseChartTabDetails = ({
  dateVariants,
  date,
  setDate,
  detailsResult,
  setDetailsResult,
}: ChatAssistantResponseChartTabDetailsProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();
  const msgDetailsForm = useRef<HTMLDivElement>(null);
  const { askQuestionDetails } = useChatGpc();

  const requestDetailedData = async () => {
    setIsLoading(true);
    setError(undefined);

    const request: RequestBody = {
      type: "breakdown",
      parameters: {
        date: getYYYYMMDDDateFormat(date!),
      },
    };

    const response = await askQuestionDetails(request);

    if ("frame" in response && response.frame.data) {
      setDetailsResult({
        data: response.frame.data,
        headers: response.frame.headers,
      });
    } else if ("error" in response && response.message) {
      setError(response.message);
    } else {
      setError("Something went wrong :(");
    }

    setIsLoading((state) => {
      // Note: Scroll down only, when isLoading was set to true before
      if (state) {
        setTimeout(() => {
          msgDetailsForm?.current?.scrollIntoView({
            behavior: "smooth",
            // @ts-ignore: field exists
            alignToTop: true,
          });
        }, 80);
      }

      return false;
    });
  };

  return (
    <>
      <div className="message-details-form" ref={msgDetailsForm}>
        <h4>
          <strong>Get BOL details for:</strong>
        </h4>
        <div className="fields-container">
          <DatePicker
            className="datepicker"
            placeholderText="select date"
            allowSameDay={true}
            selected={date}
            onChange={(date) => setDate(date)}
            includeDates={dateVariants}
          />

          <Button
            size="md"
            variant="solid"
            intent="primary"
            disabled={!date}
            onClick={requestDetailedData}
          >
            Request details
          </Button>
        </div>
      </div>
      {isLoading && (
        <div className="loading-wrapper">
          <Loading />
        </div>
      )}
      {!isLoading && detailsResult?.data && detailsResult?.headers && (
        <DatasetTable
          data={detailsResult.data}
          headers={detailsResult.headers}
          showDownloadCSVButton={false}
        />
      )}
      {!isLoading && error && (
        <Alert
          ariaLive="polite"
          showClose
          onDismiss={() => setError(undefined)}
          showLeftIcon
          size="sm"
          type="error"
          hasSeparator={false}
        >
          <AlertContent>{error}</AlertContent>
        </Alert>
      )}
    </>
  );
};

export default ChatAssistantResponseChartTabDetails;
