import { CHART_WIDTH } from "./ChatAssistantResponseChartTab";

import "./SelectColumnToVisualizeStyles.css";

type SelectColumnToVisualizeProps = {
  columns: string[];
  columnToVisualizeIndex: number;
  setColumnToVisualizeIndex: React.Dispatch<React.SetStateAction<number>>;
};

const SelectColumnToVisualize = ({
  columns,
  setColumnToVisualizeIndex,
  columnToVisualizeIndex,
}: SelectColumnToVisualizeProps) => {
  return (
    <div
      style={{ width: CHART_WIDTH }}
      className="select-column-to-visualize-container"
    >
      <select
        defaultValue={columnToVisualizeIndex}
        onChange={(event) => {
          setColumnToVisualizeIndex(Number(event.target.value));
        }}
      >
        {columns.map((column, index) => (
          <option key={column} value={index}>
            {column}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectColumnToVisualize;
