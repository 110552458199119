import { ChatMessage, ChatMessageAssistant } from "../types";
import { MdOutlineFaceUnlock } from "react-icons/md";
import "./ChatMessageComponentStyles.css";
import ChatErrorMessage from "./ChatErrorMessage";
import { Button } from "@bolt/components";
import ChatAssistantResponseMessage from "./ChatAssistantResponseMessage";
import { ChatTheme } from "../hooks/useTheme";
import MessageBot from "./MessageBot";
import SkeletonTextBlock from "./SkeletonTextBlock";
import { RefObject } from "react";

type ChatMessageComponentProps = {
  message: ChatMessage;
  // Note: It is used to determine whether on not to display "Regenerate your question" button
  isLatest: boolean;
  responding: boolean;
  // Note: For adding reference to preLatest message. Used for scrolling with visible question;
  questionAndAnswerBlockRef: RefObject<HTMLDivElement>;
  handleRegenerateResponse: () => void;
  theme: ChatTheme;
  setChatHistory: React.Dispatch<React.SetStateAction<ChatMessage[]>>;
};

const ChatMessageComponent = ({
  message,
  isLatest,
  responding,
  handleRegenerateResponse,
  theme,
  setChatHistory,
  questionAndAnswerBlockRef,
}: ChatMessageComponentProps) => {
  if (responding && questionAndAnswerBlockRef) {
    questionAndAnswerBlockRef.current?.style.setProperty(
      "min-height",
      "calc(10vh - 8rem + 9px)",
    );
  }

  // Note: This is a hack for refreshing messages related to DRY RUN feature
  if ((message as ChatMessageAssistant).text === "refresh") {
    return (
      <MessageBot>
        <div className="full-width-message-wrapper">
          <SkeletonTextBlock />
        </div>
      </MessageBot>
    );
  }

  if (message?.error) {
    return (
      <>
        <ChatErrorMessage errorMessage={message.content!} />
        {isLatest && (
          <div className="chat-action-button-wrapper">
            <Button
              onClick={handleRegenerateResponse}
              className="regenerate-response-chat-button"
              size="lg"
              variant="solid"
              intent="primary"
            >
              Regenerate your question
            </Button>
          </div>
        )}
      </>
    );
  }

  if (message.role === "assistant") {
    return (
      <ChatAssistantResponseMessage
        questionAndAnswerBlockRef={questionAndAnswerBlockRef}
        setChatHistory={setChatHistory}
        message={message}
        theme={theme}
      />
    );
  }

  return (
    <div className="message">
      <div>
        <MdOutlineFaceUnlock className="chat-img" size="100%" />
      </div>
      <p className="chat-markdown">{message.content}</p>
    </div>
  );
};

export default ChatMessageComponent;
