import {
  ChatMessage,
  ChatMessageAssistant,
  ChatMessageDataFrame,
} from "../types";
import ChatGPCLogomark from "./ChatGPCLogomark";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@bolt/components";

import "./ChatAssistantResponseMessageStyles.css";
import ChatAssistantResponseChartTab from "./ChatAssistantResponseChartTab";
import ChatAssistantResponseDetailsTab from "./ChatAssistantResponseDetailsTab";
import DatasetTable from "./DatasetTable";
import { RefObject, memo, useRef, useState, useEffect } from "react";
import { ChatTheme } from "../hooks/useTheme";
import MarkdownContent from "./MarkdownContent";
import clsx from "clsx";

type ChatAssistantResponseMessageProps = {
  message: ChatMessageAssistant;
  theme: ChatTheme;
  setChatHistory: React.Dispatch<React.SetStateAction<ChatMessage[]>>;
  questionAndAnswerBlockRef: RefObject<HTMLDivElement>;
};

const ChatAssistantResponseMessage = ({
  message,
  theme,
  setChatHistory,
  questionAndAnswerBlockRef,
}: ChatAssistantResponseMessageProps) => {
  const [columnToVisualizeIndex, setColumnToVisualizeIndex] = useState(0);
  const [date, setDate] = useState<Date | null>(null);
  const [detailsResult, setDetailsResult] = useState<ChatMessageDataFrame>();
  const showVisualizationAndDataSourceTab = !!message.frame?.data;
  const tabsRef = useRef<HTMLDivElement>(null);
  const [answerMaxHeight, setAnswerMaxHeight] = useState(0);

  useEffect(() => {
    if (answerMaxHeight > 0 && questionAndAnswerBlockRef) {
      questionAndAnswerBlockRef.current?.style.setProperty(
        "min-height",
        answerMaxHeight.toString() + "px",
      );
    }
  }, [answerMaxHeight, questionAndAnswerBlockRef]);

  /* Note: SPEC-1931
    Show summary only when there is no Visualization tab.
    Otherwise show Summary above the chart at Visualization tab.
  */
  const showSummaryTab = !showVisualizationAndDataSourceTab && message.text;
  const showDetailsTab =
    message.details?.metric_name && message.details.metric_name !== "about";

  const defaultActiveTab = showVisualizationAndDataSourceTab
    ? "visualization"
    : "summary";

  const showDateMonthly =
    message.details?.parameters.group_by_interval === "MONTH";

  const showDateYearly =
    message.details?.parameters.group_by_interval === "YEAR";

  const regulateTabContentAutoHeightEnabled = showVisualizationAndDataSourceTab;

  const tabContentExtraClassName = regulateTabContentAutoHeightEnabled
    ? "tab-content-autoheight"
    : "";

  return (
    <div className="message bot-message">
      <ChatGPCLogomark className="chat-img" />
      <Tabs
        ref={tabsRef}
        onValueChange={() => {
          if (questionAndAnswerBlockRef?.current) {
            setTimeout(() => {
              const view_height = questionAndAnswerBlockRef?.current
                ? questionAndAnswerBlockRef.current
                    .getBoundingClientRect()
                    .height.valueOf()
                : 0;

              if (answerMaxHeight < view_height) {
                setAnswerMaxHeight(view_height);
              }

              questionAndAnswerBlockRef.current?.scrollIntoView({
                // @ts-ignore: field exists
                alignToTop: true,
                behavior: "smooth",
              });
            }, 80);
          }
        }}
        defaultValue={defaultActiveTab}
        variant="with-rule-ghost"
        className="answer-tabs"
      >
        <TabsList>
          {showSummaryTab && <TabsTrigger value="summary">Summary</TabsTrigger>}

          {showVisualizationAndDataSourceTab && (
            <TabsTrigger value="visualization">Visualization</TabsTrigger>
          )}

          {showVisualizationAndDataSourceTab && (
            <TabsTrigger value="data">Data Source</TabsTrigger>
          )}

          {showDetailsTab && <TabsTrigger value="details">Details</TabsTrigger>}
          {false && <TabsTrigger value="reasoning">Reasoning</TabsTrigger>}
        </TabsList>

        {showSummaryTab && (
          <TabsContent value="summary" className={tabContentExtraClassName}>
            <MarkdownContent setChatHistory={setChatHistory}>
              {message.text!}
            </MarkdownContent>
          </TabsContent>
        )}

        {showVisualizationAndDataSourceTab && (
          <TabsContent
            value="visualization"
            className={clsx("overflow-x-auto", tabContentExtraClassName)}
          >
            <ChatAssistantResponseChartTab
              showDateMonthly={showDateMonthly}
              showDateYearly={showDateYearly}
              columnToVisualizeIndex={columnToVisualizeIndex}
              setColumnToVisualizeIndex={setColumnToVisualizeIndex}
              date={date}
              setDate={setDate}
              detailsResult={detailsResult}
              setDetailsResult={setDetailsResult}
              message={message}
              theme={theme}
            />
          </TabsContent>
        )}

        {showVisualizationAndDataSourceTab && (
          <TabsContent
            value="data"
            className={clsx("overflow-x-auto", tabContentExtraClassName)}
          >
            <DatasetTable
              data={message.frame?.data!}
              headers={message.frame?.headers!}
              showDateMonthly={showDateMonthly}
              showDateYearly={showDateYearly}
            />
          </TabsContent>
        )}

        {showDetailsTab && (
          <TabsContent value="details" className={tabContentExtraClassName}>
            <ChatAssistantResponseDetailsTab details={message.details!} />
          </TabsContent>
        )}
      </Tabs>
    </div>
  );
};

export default memo(
  ChatAssistantResponseMessage,
  (prev, next) => prev.message.questionId === next.message.questionId,
);
