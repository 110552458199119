import { useCallback, useRef } from "react";
import {
  ChatMessageAssistant,
  ChatMessageDataFrame,
  ChatRequest,
  DTO_DATE_FORMAT,
} from "../../types";
import {
  useChatDetailsContext,
  useSetChatDetailsContext,
} from "../../contexts/ChatContext";
import { useSetUserInfoContext } from "../../contexts/UserInfoContext";
import useGetDefaultRequestOptions from "../useGetDefaultRequestOptions";
import useExpirationGuard from "../useExpirationGuard";

type ChatDetailsFormResponse = {
  chat_id: string;
  message_id: string;
  frame: ChatMessageDataFrame;
  description: string;
  details: {
    metric_name: string;
    columns_to_visualize: string[];
    parameters: Record<string, string | DTO_DATE_FORMAT>;
  };
};

type ReturnedError = {
  error: boolean;
  message: string;
};

type ReturnedContent = ChatDetailsFormResponse | ReturnedError;

export const useChatGpc = () => {
  const chatDetails = useChatDetailsContext();
  const setChatDetails = useSetChatDetailsContext();
  const setUserInfo = useSetUserInfoContext();

  const abortControllerRef = useRef<AbortController | null>(null);
  const getDefaultRequestOptions = useGetDefaultRequestOptions();
  const expirationGuard = useExpirationGuard();
  const abortRequest = useCallback(() => {
    abortControllerRef.current?.abort();
  }, []);

  const askQuestion = useCallback(
    async (chatRequestBody: ChatRequest): Promise<ChatMessageAssistant> => {
      const accessToken = await expirationGuard();

      abortRequest();
      const abortController = new AbortController();
      abortControllerRef.current = abortController;

      const { chatId } = chatDetails || {};
      const CHAT_ENDPOINT =
        process.env.REACT_APP_BACKEND_URL +
        "/api/v1/chats" +
        (chatId ? `/${chatId}/messages` : "");

      const requestOptionsOverride: RequestInit = {
        method: "POST",
        body: JSON.stringify(chatRequestBody),
        signal: abortController.signal,
      };

      try {
        const response = await fetch(
          CHAT_ENDPOINT,
          getDefaultRequestOptions(requestOptionsOverride, accessToken),
        );

        if (response.status > 299) {
          if (response.status === 401) {
            setUserInfo(null);
          }
          throw new Error();
        }

        const responseJson = await response.json();

        if (responseJson.chat_id && responseJson.message_id) {
          setChatDetails((prevDetails) => ({
            ...prevDetails,
            chatId: responseJson.chat_id,
            messageId: responseJson.message_id,
          }));
        }

        return {
          ...responseJson.message,
          role: "assistant",
          questionId: responseJson.message_id,
          questionTimestamp: responseJson.timestamp,
          error: false,
        };
      } catch (error: any) {
        if (error.name === "AbortError") {
          return {
            content:
              "The response generation has been stopped. You can enter a new question or resubmit a previous one to continue.",
            role: "assistant",
            error: true,
          };
        }

        return {
          content:
            "We're sorry, there was an unexpected error. Please try resubmitting your question. If you keep getting this message, contact us.",
          role: "assistant",
          error: true,
        };
      }
    },
    [
      chatDetails,
      abortRequest,
      setChatDetails,
      setUserInfo,
      getDefaultRequestOptions,
      expirationGuard,
    ],
  );

  const askQuestionDetails = useCallback(
    async (request: Record<string, any>): Promise<ReturnedContent> => {
      if (!chatDetails) {
        throw new Error("Chat details are not available");
      }

      abortRequest();
      const abortController = new AbortController();
      abortControllerRef.current = abortController;

      const { chatId, messageId } = chatDetails;
      const FORM_ENDPOINT =
        process.env.REACT_APP_BACKEND_URL +
        `/api/v1/chats/${chatId}/messages/${messageId}/form`;

      const requestOptionsOverride = {
        method: "POST",
        body: JSON.stringify(request),
        signal: abortController.signal,
      };

      try {
        const response = await fetch(
          FORM_ENDPOINT,
          getDefaultRequestOptions(requestOptionsOverride),
        );

        if (response.status > 299) {
          switch (response.status) {
            case 400:
              throw new Error("Invalid request parameters.");
            case 401:
              throw new Error("The user is not authorized");
            case 404:
              throw new Error(
                "The specified chat, message, or data for the given date does not exist.",
              );
            default:
              throw new Error();
          }
        }

        const responseJson: ChatDetailsFormResponse = await response.json();

        return responseJson;
      } catch (e: any) {
        console.error("Request form error:", e);

        return {
          error: true,
          message: e.message,
        };
      }
    },
    [chatDetails, abortRequest, getDefaultRequestOptions],
  );

  const confirmQuestion = useCallback(
    async (confirmURL: string): Promise<ChatMessageAssistant> => {
      const accessToken = await expirationGuard();

      abortRequest();
      const abortController = new AbortController();
      abortControllerRef.current = abortController;

      const CHAT_ENDPOINT =
        process.env.REACT_APP_BACKEND_URL + "/api/v1/chats" + confirmURL;

      const requestOptionsOverride: RequestInit = {
        method: "GET",
        signal: abortController.signal,
      };

      try {
        const response = await fetch(
          CHAT_ENDPOINT,
          getDefaultRequestOptions(requestOptionsOverride, accessToken),
        );

        if (response.status > 299) {
          if (response.status === 401) {
            setUserInfo(null);
          }
          throw new Error();
        }

        const responseJson = await response.json();

        // TODO: to refactore and make it DRY. It's copied from askQuestion funciton above.
        if (responseJson.chat_id && responseJson.message_id) {
          setChatDetails((prevDetails) => ({
            ...prevDetails,
            chatId: responseJson.chat_id,
            messageId: responseJson.message_id,
          }));
        }

        return {
          ...responseJson.message,
          role: "assistant",
          questionId: responseJson.message_id,
          questionTimestamp: responseJson.timestamp,
          error: false,
        };
      } catch (error: any) {
        if (error.name === "AbortError") {
          return {
            content:
              "The response generation has been stopped. You can enter a new question or resubmit a previous one to continue.",
            role: "assistant",
            error: true,
          };
        }

        return {
          content:
            "We're sorry, there was an unexpected error. Please try resubmitting your question. If you keep getting this message, contact us.",
          role: "assistant",
          error: true,
        };
      }
    },
    [
      abortRequest,
      setChatDetails,
      setUserInfo,
      getDefaultRequestOptions,
      expirationGuard,
    ],
  );

  return {
    askQuestion,
    askQuestionDetails,
    chatDetails,
    setChatDetails,
    abortRequest,
    confirmQuestion,
  };
};
