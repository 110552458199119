import { TableCell } from "@bolt/components";
import { memo } from "react";
import { LOCALE_APP } from "../config";
import { DataCell } from "../types";
import convertMinutesToHHMM from "../utils/convertMinutesToHHMM";

type DatasetTableCellProps = {
  value: DataCell;
  type: string;
  showDateMonthly: boolean;
  showDateYearly: boolean;
};

const DatasetTableCell = ({
  value,
  type,
  showDateMonthly,
  showDateYearly,
}: DatasetTableCellProps) => {
  if (type === "datetime" || type === "date") {
    if (showDateYearly) {
      return (
        <TableCell title={value as string}>
          {new Date(value).toLocaleDateString(LOCALE_APP, {
            year: "numeric",
          })}
        </TableCell>
      );
    }

    if (showDateMonthly) {
      return (
        <TableCell title={value as string}>
          {new Date(value).toLocaleDateString(LOCALE_APP, {
            year: "numeric",
            month: "short",
          })}
        </TableCell>
      );
    }
  }

  switch (type) {
    case "datetime":
      return (
        <TableCell title={value as string}>
          {new Date(value).toLocaleString(LOCALE_APP)}
        </TableCell>
      );

    case "date":
      return (
        <TableCell title={value as string}>
          {new Date(value).toLocaleDateString(LOCALE_APP)}
        </TableCell>
      );

    case "percentage":
      return <TableCell>{value}%</TableCell>;

    case "currency":
      return (
        <TableCell>
          {new Intl.NumberFormat("us-US", {
            style: "currency",
            currency: "USD",
          }).format(Number(value))}
        </TableCell>
      );
    case "minutes":
      return <TableCell>{convertMinutesToHHMM(Number(value))}</TableCell>;
  }

  return <TableCell>{value}</TableCell>;
};

export default memo(DatasetTableCell);
